.pop-up-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    max-height: 100vh;
    height: 100%;
    background: rgba(52, 64, 84, 0.6);
    backdrop-filter: blur(8px);
    overflow: hidden;
    display: none;
    padding: 0 1rem;

    &.pop-up-container--visible {
        display: block;
    }
}

.pop-up {
    position: relative;
    top: 100%;
    transform: translate(0, calc(-100% - 5rem));
    background-color: #fff;
    max-width: 25rem;
    margin: 0 auto;
    bottom: 5rem;
    padding: 1.5rem;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
    border-radius: 12px;
}

.pop-up--fullscreen-mobile {
    width: 100vw;
    max-height: 100vh;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: none;
    max-width: none;
    border-radius: 0;
}

body:has(.pop-up-container--visible) {
    overflow-y: hidden;
}

@media screen and (min-width: 768px) {
    .pop-up {
        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
        text-align: start;
    }

    .pop-up--fullscreen-mobile {
        max-width: 25rem;
        height: auto;
        position: relative;
        left: auto;
        border-radius: 12px;
    }
}
