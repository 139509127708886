@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './theme-colors.scss';
@import './utils.scss';
@import './flex.scss';

html {
    font-size: 16px;
}

*, *:before, *:after {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

a, a:visited, a:hover, a:active {
    color: inherit;
}

button {
    border: none;
    background: none;
}

.cursor-pointer {cursor: pointer;}
.pointer-events-none {pointer-events: none;}

.view {
    padding: 2rem 1.5rem;
}

@media only screen and (min-width: 768px) {
    .view {
        padding: 4rem 4rem 6.25rem;
    }
}

@media only screen and (min-width: 1376px) {
    .view {
        padding: 4rem 5rem 6.25rem;
    }
}

//
// Typography
//
.light {font-weight: 300;}
.regular {font-weight: 400;}
.medium {font-weight: 500;}
.semibold {font-weight: 600;}
.bold {font-weight: 700;}
.display-2xl {font-size: 4.5rem; line-height: 5.636rem;}
.display-xl {font-size: 3.75rem; line-height: 4.625rem;}
.display-lg {font-size: 3rem; line-height: 3.75rem;}
.display-md {font-size: 2.25rem; line-height: 2.75rem;}
.display-sm {font-size: 1.875rem; line-height: 2.375rem;}
.display-xs {font-size: 1.5rem; line-height: 2rem;}
.text-header {font-size: 1.875rem; line-height: 2.375rem}
.text-xl {font-size: 1.25rem; line-height: 1.875rem;}
.text-lg {font-size: 1.125rem; line-height: 1.75rem;}
.text-md {font-size: 1rem; line-height: 1.5rem;}
.text-sm {font-size: 0.875rem; line-height: 1.25rem;}
.text-xs {font-size: 0.75rem; line-height: 1.125rem;}
.text-title {font-size: 1.5rem; line-height: 2rem;}
.text-align-center {text-align: center;}
.text-align-right {text-align: right;}
.shadow-lg {box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.04);}

@media only screen and (min-width: 768px) {
    .mw768-display-2xl {font-size: 4.5rem; line-height: 5.636rem;}
    .mw768-display-xl {font-size: 3.75rem; line-height: 4.625rem;}
    .mw768-display-lg {font-size: 3rem; line-height: 3.75rem;}
    .mw768-display-md {font-size: 2.25rem; line-height: 2.75rem;}
    .mw768-display-sm {font-size: 1.875rem; line-height: 2.375rem;}
    .mw768-display-xs {font-size: 1.5rem; line-height: 2rem;}
    .mw768-text-xl {font-size: 1.25rem; line-height: 1.875rem;}
    .mw768-text-lg {font-size: 1.125rem; line-height: 1.75rem;}
    .mw768-text-md {font-size: 1rem; line-height: 1.5rem;}
    .mw768-text-sm {font-size: 0.875rem; line-height: 1.25rem;}
    .mw768-text-xs {font-size: 0.75rem; line-height: 1.125rem;}
    .text-title {font-size: 2.25rem; line-height: 2.75rem;}
}

@mixin color-modifiers($map, $attribute) {
    @each $key, $value in $map {
        &-#{$key} {
            #{$attribute}: $value;
        }
    }
}

.border-color {
    @include color-modifiers($colors, 'border-color');
}

@media only screen and (min-width: 768px) {
    .mw768 {
        &-border-color {
            @include color-modifiers($colors, 'border-color');
        }
    }
}

//.color-gray-25 {
//	color: #FCFCFD;
//
//	path {
//		stroke: #FCFCFD;
//	}
//}
.color {
    @include color-modifiers($colors, 'color');
}
//.background-color-gray-25 {
//	background-color: #FCFCFD;
//}
.background-color {
    @include color-modifiers($colors, 'background-color');
}
@media only screen and (min-width: 768px) {
    .mw768 {
        &-background-color {
            @include color-modifiers($colors, 'background-color');
        }
    }
}


//
// Spacing
//
$spacings: (
    '0': 0, '0dot125rem': 0.125rem, '0dot25rem': 0.25rem, '0dot375rem': 0.375rem, '0dot5rem': 0.5rem, '0dot625rem': 0.625rem, '0dot75rem': 0.75rem, '0dot875rem': 0.875rem,
    '1rem': 1rem, '1dot25rem': 1.25rem, '1dot5rem': 1.5rem, '1dot75rem': 1.75rem,
    '2rem': 2rem, '2dot25rem': 2.25rem, '2dot5rem': 2.5rem, '2dot75rem': 2.75rem,
    '3rem': 3rem, '3dot25rem': 3.25rem, '3dot5rem': 3.5rem, 
    '4rem': 4rem, '4dot25rem': 4.25rem, '4dot5rem': 4.5rem,
    '6rem': 6rem, '6dot125rem': 6.125rem,
    '7rem': 7rem, '7dot5rem': 7.5rem,
    '8rem': 8rem,
    '14rem': 14rem, '14dot375rem': 14.375rem,
    '26dot5rem': 26.5rem,
    '33rem' : 33rem
);

@mixin spacing-modifiers($map, $type) {
    @each $key, $value in $map {
        .#{$type}-#{$key} {
            #{$type}: $value;
        }
        .first-child\:#{$type}-#{$key}:first-child {
            #{$type}: $value;
        }
    }
}
@mixin spacing-modifiers-ex($map, $type) {
    @each $key, $value in $map {
        &-#{$type}-#{$key} {
            #{$type}: $value;
        }
    }
}

@mixin spacing-modifiers-ex0($map, $type, $type1) {
    @each $key, $value in $map {
        .#{$type}-#{$type1}-#{$key} {
            -webkit-#{$type}: unquote($type1+unquote('(#{$value})'));
            #{$type}: unquote($type1+unquote('(#{$value})'));
        }
    }
}

// .backdrop-filter-blur-0dot25rem {
// 	-webkit-backdrop-filter: blur(0.25rem);
// 	backdrop-filter: blur(0.25rem);
// }
@include spacing-modifiers-ex0($spacings, 'backdrop-filter', 'blur');

//.min-height-0dot25rem {
//	min-height: 0.25rem;
//}
// .first\:min-height-0dot25rem:first-child {
// 	min-height: 0.25rem;
// }
@include spacing-modifiers($spacings, 'min-height');
@include spacing-modifiers($spacings, 'max-height');
@include spacing-modifiers($spacings, 'min-width');
@include spacing-modifiers($spacings, 'max-width');
@include spacing-modifiers($spacings, 'width');
@include spacing-modifiers($spacings, 'height');
@include spacing-modifiers($spacings, 'border-radius');
@include spacing-modifiers($spacings, 'margin-left');
@include spacing-modifiers($spacings, 'margin-right');
@include spacing-modifiers($spacings, 'margin-top');
@include spacing-modifiers($spacings, 'margin-bottom');
@include spacing-modifiers($spacings, 'padding');
@include spacing-modifiers($spacings, 'padding-left');
@include spacing-modifiers($spacings, 'padding-right');
@include spacing-modifiers($spacings, 'padding-top');
@include spacing-modifiers($spacings, 'padding-bottom');
@include spacing-modifiers($spacings, 'left');
@include spacing-modifiers($spacings, 'right');
@include spacing-modifiers($spacings, 'top');
@include spacing-modifiers($spacings, 'bottom');

@media only screen and (min-width: 768px) {
    .mw768 {
        @include spacing-modifiers-ex($spacings, 'min-height');
        @include spacing-modifiers-ex($spacings, 'max-height');
        @include spacing-modifiers-ex($spacings, 'min-width');
        @include spacing-modifiers-ex($spacings, 'max-width');
        @include spacing-modifiers-ex($spacings, 'width');
        @include spacing-modifiers-ex($spacings, 'height');
        @include spacing-modifiers-ex($spacings, 'border-radius');
        @include spacing-modifiers-ex($spacings, 'margin-left');
        @include spacing-modifiers-ex($spacings, 'margin-right');
        @include spacing-modifiers-ex($spacings, 'margin-top');
        @include spacing-modifiers-ex($spacings, 'margin-bottom');
        @include spacing-modifiers-ex($spacings, 'padding');
        @include spacing-modifiers-ex($spacings, 'padding-left');
        @include spacing-modifiers-ex($spacings, 'padding-right');
        @include spacing-modifiers-ex($spacings, 'padding-top');
        @include spacing-modifiers-ex($spacings, 'padding-bottom');
    }
}
